import $ from 'jquery'
import { attachDynamicEvent } from '~/common/helpers/dom'
import ActiveStorageDirectUploader from './active_storage_direct_uploader'

// tries to replicate app/javascript/controllers/file_controller.js

const listFiles = (filesUploader, filesListTarget) => {
  const files = filesUploader.files
  filesListTarget.empty()
  if (files.length > 0) {
    const title = document.createElement('p')
    title.textContent = 'Selected files:'
    filesListTarget.append(title)
  }
  let file
  for (let i = 0; i < files.length; i++) {
    file = document.createElement('li')
    file.classList.add('listed_file')
    file.textContent = files[i].name
    filesListTarget.append(file)
  }
}

// clear files list after clicking file-upload-btn
attachDynamicEvent('.file-upload-btn', 'click', (e) => {
  let filesListTarget = $('[data-file-target=filesList]')

  // If found more than 1 node to list the files, try to find the one that belongs to current form
  if (filesListTarget.length > 1) {
    filesListTarget = $(e.target)
      .closest('form')
      .find('[data-file-target=filesList]')
  }

  // If found more than 1 node to list the files even inside same form, try to find the one inside the same wrapper
  if (filesListTarget.length > 1) {
    filesListTarget = $(e.target)
      .closest('.active_storage_files_wrapper')
      .find('[data-file-target=filesList]')
  }

  if (filesListTarget) {
    e.target.value = ''
    filesListTarget.empty()
  }
})

attachDynamicEvent('.active_storage_file_uploader_input', 'change', (e) => {
  let filesListTarget = $('[data-file-target=filesList]')

  // If found more than 1 node to list the files, try to find the one that belongs to current form
  if (filesListTarget.length > 1) {
    filesListTarget = $(e.target)
      .closest('form')
      .find('[data-file-target=filesList]')

    // If found more than 1 node to list the files even inside same form, try to find the one inside the same wrapper
    if (filesListTarget.length > 1) {
      filesListTarget = $(e.target)
        .closest('.active_storage_files_wrapper')
        .find('[data-file-target=filesList]')
    }
  }

  // handle direct upload
  if (
    e.target.hasAttribute('data-direct-upload-url') &&
    filesListTarget.length > 0
  ) {
    const uploader = new ActiveStorageDirectUploader(
      e.target,
      filesListTarget[0]
    )
    uploader.handleFilesUpload()
  } else if (
    // list file if there is a div to render chosen files, and we're not doing a direct-upload
    filesListTarget &&
    !e.target.hasAttribute('data-direct-upload-url')
  ) {
    listFiles(e.target, filesListTarget)
  }
})
