import $ from 'jquery'
import { attachDynamicEvent } from '~/common/helpers/dom'

const deleteFile = (e) => {
  e.preventDefault()

  const hiddenFieldTemplateTarget = $(
    '[data-file-target=hiddenFieldTemplate]'
  )[0]

  let hiddenFieldTemplateNode
  let parentNodeForNewHiddenValues

  if (hiddenFieldTemplateTarget) {
    // the form builds a hidden value field template that we'll use as an example for new hidden values
    hiddenFieldTemplateNode = hiddenFieldTemplateTarget.cloneNode()
    parentNodeForNewHiddenValues = $(hiddenFieldTemplateTarget).parent()
    // remove the template with empty value so no empty value is passed to controller
    hiddenFieldTemplateTarget.remove()
  }
  if (hiddenFieldTemplateNode && parentNodeForNewHiddenValues) {
    const fileId = Number(e.target.dataset.fileId)
    const parent = $(e.target).parent().first()

    // when a file is clicked, append a hidden field with its id for the controlller to delete
    let newNodeCopy = hiddenFieldTemplateNode.cloneNode()
    newNodeCopy.value = fileId

    parentNodeForNewHiddenValues.append(newNodeCopy)

    parent.remove()
  }
}

attachDynamicEvent('.active-storage-file-delete-btn', 'click', (e) =>
  deleteFile(e)
)
